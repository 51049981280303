import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            tab: {
                'left': "Filters",
                'main': "Events",
                'right': "General info",
            },
            'filters': 'Filters'
        }
    },
    pl: {
        translation: {
            tab: {
                'left': "Filtry",
                'main': "Treść",
                'right': "Ogólne informacje",
            },
            'filters': 'Filtry'
        }
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;