import React from 'react'
import { Page, Text, View, Document, StyleSheet, BlobProvider, Image, Font } from '@react-pdf/renderer'
import showdown from 'showdown'
import moment from 'moment'

Font.register({ family: 'LightLato', src: '/Lato/Lato-Light.ttf' });
Font.register({ family: 'RegularLato', src: '/Lato/Lato-Medium.ttf' });
Font.register({ family: 'BoldLato', src: '/Lato/Lato-Heavy.ttf' });

const converter = new showdown.Converter()
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        fontFamily: 'RegularLato',
        padding: '10mm'
    },
    topSection: {
        backgroundColor: 'white',
        padding: '24px 0',
        textAlign: 'center',
        // marginLeft: '-10mm',
        // marginTop: '-10mm',
        // width: 'calc(100% + 20mm)'
    },
    whiteTextBig: {
        color: 'black',
        fontFamily: 'BoldLato',
        fontSize: '20pt'
    },
    whiteTextSmall: {
        color: 'black',
        fontFamily: 'LightLato',
        fontSize: '12pt'
    },
    bottomSection: {
        padding: '0',
        flexDirection: 'row',
        alignItems: 'stretch'
    },
    leftColumn: {
        width: '140mm',
        flexGrow: 1
    },
    rightColumn: {
        width: '60mm',
        paddingLeft: '5mm',
        paddingRight: '5mm',
        flexGrow: 1
    },
    header: {
        fontFamily: 'BoldLato',
        marginTop: "18pt",
        marginBottom: '12pt',
        fontSize: '13pt',
        textDecoration: 'underline'
    },
    rightheader: {
        fontFamily: 'BoldLato',
        marginTop: "18pt",
        marginBottom: '4pt',
        fontSize: '12pt',
        textDecoration: 'underline'
    },
    dates: {
        fontFamily: 'LightLato',
        fontSize: '10pt',
        width: '30mm',
        // paddingTop: '1mm'
    },
    subheader: {
        marginTop: '2pt',
        fontFamily: 'BoldLato',
        fontSize: '11pt',
    },
    bodyText: {
        fontFamily: 'LightLato',
        fontSize: '10pt',
        marginTop: '2pt',
    },
    bodyright: {
        fontFamily: 'LightLato',
        fontSize: '9pt',
    },
    tagsText: {
        fontFamily: 'RegularLato',
        fontSize: '10pt',
        marginTop: '2pt',
        color: '#666666',
        marginBottom: '12pt'
    },
    addText: {
        fontFamily: 'LightLato',
        fontSize: '8pt',
    }
})

const formatDates = (start, end) => {
    if (start && end && moment(start).format('YYYY') === moment(end).format('YYYY')) return moment(start).format('YYYY')
    if (start && end) return `${moment(start).format('YYYY')} - ${moment(end).format('YYYY')}`
    if (start) return `od ${moment(start).format('YYYY')}`
}

const convertColor = color => {
    const colors = {
        sky: 'purple',
        black: 'neutral',
        green: 'green',
        yellow: 'yellow'
    }
    return colors[color]
}


export default ({ lists, sidebar, name, sub }) => {
    return <Document>
        <Page size='A4' style={styles.page} wrap>
            <View style={styles.topSection}>
                <Text style={styles.whiteTextBig}>{name}</Text>
                <Text style={styles.whiteTextSmall}>{sub}</Text>
            </View>
            <View style={styles.bottomSection}>
                <View style={styles.leftColumn}>
                    {lists.map(list => <View>
                        <Text style={styles.header}>{list.name}</Text>
                        {list.cards.map(card => {
                            const texts = converter.makeHtml(card.desc).replace(/<[^>]*>?/gm, '').split('\n')
                            return <View style={{
                                flexDirection: 'row',
                                width: '100%',
                            }}>
                                <Text style={styles.dates}>{formatDates(card.start_date, card.end_date)}</Text>
                                <View style={{
                                    width: '110mm'
                                }}>
                                    <Text style={styles.addText}>{card.subtitle ? card.subtitle.toUpperCase() : ''}</Text>
                                    <Text style={styles.subheader}>
                                        {card.name}
                                    </Text>
                                    {texts.map(text => <Text style={styles.bodyText}>{text}</Text>)}
                                    <Text style={styles.tagsText}>
                                        {card.tags.map(tag => '#' + tag.name).join(', ')}
                                    </Text>
                                </View>
                            </View>
                        })}
                    </View>)}
                </View>
                <View style={styles.rightColumn}>
                    {sidebar.map(card => {
                        const texts = converter.makeHtml(card.desc).replace(/<[^>]*>?/gm, '').split('\n')
                        return <View>
                            <Text style={styles.rightheader}>{card.name}</Text>
                            {texts.map(text => <Text style={styles.bodyright}>{text}</Text>)}
                        </View>
                    })}
                </View>
            </View>
        </Page>
    </Document>
}