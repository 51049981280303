import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import showdown from "showdown";
import moment from "moment";
import styled from "@emotion/styled";
import FlipMove from "react-flip-move";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Doc from "./Doc";

const converter = new showdown.Converter();

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
  margin: 16px 0;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CV = styled.div`
  font-size: 16px;

  .header {
    display: flex;
    background: black;
    padding: 64px 0;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .name {
      color: white;
      font-size: 32px;
    }

    .subname {
      color: white;
      font-size: 16px;
      margin-top: 16px;
    }
  }

  .tabs {
    display: none;
    @media (max-width: 1000px) {
      display: flex;
    }
    justify-content: center;
    margin: 0 auto;
    padding-top: 16px;

    .tab {
      padding: 0 8px;
      color: inherit;
      text-decoration: none;

      &.active {
        border-bottom: 2px solid black;
      }
    }
  }

  .columns {
    display: flex;
    max-width: 1200px;
    padding: 0 16px;
    width: 100%;
    margin: 0 auto;
    flex-direction: row;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .left {
      padding-top: 16px;
      width: 240px;
      font-weight: 200;
      color: rgba(0,0,0,0.7);
      
      @media (max-width: 1000px) {
        width: 100%;
        display: none;

        &.active {
          display: block;
        }
      }

      .heading {
        font-weight: bold;
        font-size: 14px;
        padding-left: 8px;
      }

      label {
        display: block;
        padding: 4px 8px;
        cursor: pointer;

        &:hover {
          background: rgba(0,0,0,0.05);
        }

        input {
          margin-right: 8px;
          float: right;
          position: relative;
          top: 3px;
        }
      }
    }

    .right {
      width: 240px;
      color: rgba(0,0,0,0.7);
      font-weight: 200;
      
      @media (max-width: 1000px) {
        width: 100%;

        display: none;

        &.active {
          display: block;
        }
      }

      .heading {
        margin: 16px 0 4px;
        font-weight: 600;
        font-size: 14px;
        color: black;
      }

      hr {
        border: 0;
        height: 1px;
        width: 100%;
        background: rgba(0,0,0,0.2);
      }

      p {
        margin: 0;
      }
    }

    .main {
      width: calc(100% - 480px);
      @media (max-width: 1000px) {
        width: 100%;

        display: none;

        &.active {
          display: block;
        }
      }
      padding: 0 16px;

      .heading {
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        padding-left: 16px;
        margin-top: 16px;
      }

      .card {
        margin-bottom: 16px;
        padding: 16px;
        display: flex;

        .dates {
          width: 100px;
          font-weight: 200;
          color: rgba(0,0,0,0.5);
        }

        .rest {
          width: calc(100% - 100px);

          .subtitle {
            font-weight: 200;
            color: rgba(0,0,0,0.5);
            margin-bottom: -2px;
          }

          .title {
            font-weight: 600;
            font-size: 20px;
          }

          .content {
            margin: 4px 0;
            color: rgba(0,0,0,0.7);
            p {
              margin: 0;

              strong {
                font-weight: inherit;
              }
            }

          }

          .tags {
            margin-top: 8px;
            div {
              float: left;
              margin-right: 4px;
              margin-bottom: 4px;
              color: white;
              font-weight: 600;
              padding: 0 8px;
            }

            .green {
              background: rgba(68, 189, 50,1.0);
            }

            .yellow {
              background: rgba(225, 177, 44,1.0);
            }

            .black {
              background: rgba(47, 54, 64,1.0);
            }

            .sky {
              background: rgba(0, 151, 230,1.0);
            }

          }
        }
      }
    }
  }
`;

const formatDates = (start, end) => {
  if (
    start &&
    end &&
    moment(start).format("YYYY") === moment(end).format("YYYY")
  )
    return moment(start).format("YYYY");
  if (start && end)
    return `${moment(start).format("YYYY")} - ${moment(end).format("YYYY")}`;
  if (start) return `from ${moment(start).format("YYYY")}`;
};

const convertColor = color => {
  const colors = {
    sky: "purple",
    black: "neutral",
    green: "green",
    yellow: "yellow"
  };
  return colors[color];
};

export default ({
  match: {
    params: { trelloId }
  }
}) => {
  const [activeTab, setActiveTab] = useState("main");
  const [board, setBoard] = useState(false);
  const [filters, setFilters] = useState([]);
  const { t, i18n } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const noHeader = urlParams.get("noHeader");

  // const { width } = size;
  //         const small = width < 1000;
  //         return (

  const toggleTag = tag => {
    setFilters(_.xor(filters, [tag]));
  };

  useEffect(() => {
    axios.get(`https://cache.tresume.kriz.app/${trelloId}`).then(({ data }) => {
      setBoard(data);
    });
  }, []);

  if (!board)
    return (
      <LoadingOverlay>
        <div className="lds-dual-ring"></div>
      </LoadingOverlay>
    );

  const filteredLists = board.lists.filter(el => el.cards.length).map(list => ({
    ...list,
    cards: list.cards.filter(
      card =>
        filters.length === 0 ||
        _.intersection(
          card.tags.map(tag => tag.name),
          filters
        ).length > 0
    )
  }));

  return (
    <CV>
      {!noHeader && (
        <div className="header">
          <div className="name">{board.name}</div>
          <div className="subname">{board.subName}</div>
          <PDFDownloadLink
            document={
              <Doc
                name={board.name}
                sub={board.subName}
                lists={filteredLists}
                sidebar={board.sidebar.cards}
              />
            }
            fileName="CV.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <div></div>
              ) : (
                <div style={{ position: "absolute", right: 24, top: 24 }}>
                  <img src="/pdf-128.png" style={{ width: 32 }} />
                </div>
              )
            }
          </PDFDownloadLink>
        </div>
      )}
      <div className="tabs">
        {["left", "main", "right"].map(tab => (
          <a
            className={"tab " + (activeTab === tab ? 'active' : '')}
            key={tab}
            href="#"
            onClick={() => setActiveTab(tab)}
          >
            {t("tab." + tab)}
          </a>
        ))}
      </div>
      <div className="columns">
        <div className={"left" + (activeTab === "left" ? " active" : "")}>
                <div className="heading">filtry</div>
          {Object.keys(board.tags).map(tagColor => (
            <div>
              {board.tags[tagColor].map(tag => (
                <label>
                  <input
                  type="checkbox"
                  onChange={() => toggleTag(tag)}
                  checked={filters.some(el => el === tag)}
                />
                  {tag}
                </label>
              ))}
              <Divider />
            </div>
          ))}
        </div>
        <div className={"main" + (activeTab === "main" ? " active" : "")}>
          {filteredLists.map(list => (
            <div>
                <div className="heading">{list.name}</div>
              <FlipMove typeName={null}>
                {list.cards.length === 0 && (
                  <div>
                    <div>No elements matching the criteria</div>
                  </div>
                )}
                {list.cards.map(card => (
                  <div className="card">
                    <div className='dates'>{formatDates(card.start_date, card.end_date)}</div>
                    <div className='rest'>
                    <div className='subtitle'>
                        {card.subtitle}
                      </div>
                      <div className="title">{card.name}</div>
                        <div className='content'
                          dangerouslySetInnerHTML={{
                            __html: converter.makeHtml(card.desc)
                          }}
                        />
                        <div className='tags'>
                      {card.tags.map(tag => (
                        <div className={tag.color}>
                          {tag.name}
                        </div>
                      ))}
                      </div>
                    </div>
                  </div>
                ))}
              </FlipMove>
              <Divider />
            </div>
          ))}
        </div>
        <div className={"right" + (activeTab === "right" ? " active" : "")}>
          {board.sidebar.cards.map(card => (
            <>
              <div className="heading">{card.name}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(card.desc)
                }}
              />
            </>
          ))}
        </div>
      </div>
    </CV>
  );
};
