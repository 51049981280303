import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Landing from './Landing';
import * as serviceWorker from './serviceWorker';
import { define, render } from 'hybrids';
import './i18n'
import { BrowserRouter as Router, Route } from "react-router-dom"

ReactDOM.render(<Router>
    <>
        <Route path='/' exact component={Landing} />
        <Route path='/:trelloId' exact component={App} />
    </>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// function reactify(fn) {
//     return render(
//         (host) => {
//             const Component = fn(host);
//             return (host, target) => ReactDOM.render(Component, target);
//         },
//         { shadowRoot: false },
//     );
// }

// const Treponent = {
//     count: 0,
//     render: reactify(({ id }) => <App match={{
//         params: {
//             trelloId: id
//         }
//     }} />),
// };

// define('treponent', Treponent);